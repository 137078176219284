<template>
  <div id="guide">
    <h1>Bill Layout</h1>
    <h2>bill layout은 자식을 세로로 배열하고 각 사이에 30px의 간격을 줍니다.</h2>
    <bill-layout>
      <div style="background-color: yellowgreen; width: 100%; height: 100px">첫번째</div>
      <span style="background-color: peru; width: 100%; height: 50px">두번째</span>
      <p style="background-color: darkgreen; color: white; width: 100%">세번째</p>
      <p>네번째</p>
    </bill-layout>
    <prism-editor class="my-editor height-300" v-model="code[6]" :highlight="highlighter"></prism-editor>
    <h1>Form Layout</h1>
    <h2>
      form layout에는 title과 content를 슬롯으로 전달하여 사용할 수 있습니다. content에 전달된 element를 세로로 배열하며
      10px의 간격을 줍니다
    </h2>
    <form-layout>
      <template #title> <i class="fal fa-receipt"></i> 상품 상세 정보</template>
      <template #content>
        <div style="height: 150px; background-color: black; color: white">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus placeat fugit quasi perferendis iste
          suscipit, nemo veritatis sit aut maiores dolores, numquam cum asperiores est non ad vel laboriosam libero quam
          quidem quisquam! Tenetur, nam dolore aspernatur qui iste deleniti tempore magni itaque vero quos minus aperiam
          distinctio placeat nihil nobis voluptas ex neque repellendus dolores? Quis fugiat velit odio est officia odi\
        </div>
        <div style="height: 150px; background-color: black; color: white">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus placeat fugit quasi perferendis iste
          suscipit, nemo veritatis sit aut maiores dolores, numquam cum asperiores est non ad vel laboriosam libero quam
          quidem quisquam! Tenetur, nam dolore aspernatur qui iste deleniti tempore magni itaque vero quos minus aperiam
          distinctio placeat nihil nobis voluptas ex neque repellendus dolores? Quis fugiat velit odio est officia odi\
        </div>
      </template>
    </form-layout>
    <prism-editor class="my-editor height-300" v-model="code[7]" :highlight="highlighter"></prism-editor>
    <h1>ColumnLayout</h1>
    <h2>
      column layout은 title과 content를 슬롯으로 전달하여 사용하거나 full-content 슬롯을 단독으로 전달하여 사용할 수
      있습니다.
    </h2>
    <div>
      <column-layout>
        <template #title>
          <div style="background-color: black; color: white">제목</div>
        </template>
        <template #content>
          <div style="background-color: peru">내용</div>
        </template>
      </column-layout>
      <column-layout>
        <template #full-content>
          <div>제목을 사용하지 않고 한줄을 모두 사용하는 경우</div>
        </template>
      </column-layout>
    </div>
    <prism-editor class="my-editor height-300" v-model="code[8]" :highlight="highlighter"></prism-editor>
    <h1>table layout과 column layout 함께 사용하기</h1>
    <h2>table layout과 column layout을 함께 사용하면 column layout에 border를 그려줍니다.</h2>
    <table-layout>
      <column-layout>
        <template #title>
          <div style="background-color: black; color: white">제목</div>
        </template>
        <template #content>
          <div style="background-color: peru">내용</div>
        </template>
      </column-layout>
      <column-layout>
        <template #full-content>
          <div>제목을 사용하지 않고 한줄을 모두 사용하는 경우</div>
        </template>
      </column-layout>
    </table-layout>
    <h1>info 모달</h1>
    <div>
      <button class="gt-button gt-button-primary" @click="$GabiaModal.show('guide-temp-modal')">누르세요</button>
    </div>
    <info-modal name="guide-temp-modal">
      <template #title> 제목 </template>
      <template #contet> 내용 </template>
    </info-modal>
    <prism-editor class="my-editor height-300" v-model="code[9]" :highlight="highlighter"></prism-editor>

    <h1>스탭퍼 인풋</h1>
    <h2>비활성화</h2>
    <minus-plus-number-input :min="10" :max="100" />
    <prism-editor class="my-editor height-300" v-model="code[0]" :highlight="highlighter"></prism-editor>

    <h2>활성화</h2>
    <minus-plus-number-input :isActive="true" :min="10" :max="100" v-model="example.value" v-bind="$attrs" />
    <prism-editor class="my-editor height-300" v-model="code[1]" :highlight="highlighter"></prism-editor>

    <h1>버튼</h1>
    <h2>기본</h2>
    <button class="gt-button">버튼</button>
    <prism-editor class="my-editor" v-model="code[2]" :highlight="highlighter"></prism-editor>
    <h2>스몰 버튼</h2>
    <button class="gt-button gt-button-small">버튼</button>
    <prism-editor class="my-editor" v-model="code[3]" :highlight="highlighter"></prism-editor>
    <h2>엑스 스몰 버튼</h2>
    <button class="gt-button gt-button-xsmall">버튼</button>
    <prism-editor class="my-editor" v-model="code[4]" :highlight="highlighter"></prism-editor>
    <h2>스타일드 버튼</h2>
    <button class="gt-button gt-button-primary">버튼</button>
    <button class="gt-button gt-button-secondary">버튼</button>
    <button class="gt-button gt-button-info">버튼</button>
    <button class="gt-button gt-button-ghost">버튼</button>
    <button class="gt-button gt-button-text">버튼</button>
    <button class="gt-button gt-button-disabled">버튼</button>
    <prism-editor class="my-editor" v-model="code[5]" :highlight="highlighter"></prism-editor>
  </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
import MinusPlusNumberInput from '@/components/inputs/MinusPlusNumberInput.vue';
import BillLayout from '@/layouts/BillLayout.vue';
import FormLayout from '@/layouts/FormLayout.vue';
import ColumnLayout from '@/layouts/ColumnLayout.vue';
import TableLayout from '@/layouts/TableLayout.vue';
import InfoModal from '@/components/common/InfoModal/InfoModal.vue';

export default {
  components: {
    PrismEditor,
    MinusPlusNumberInput,
    BillLayout,
    FormLayout,
    ColumnLayout,
    TableLayout,
    InfoModal,
  },
  metaInfo: {
    title: '가이드 페이지',
  },
  data: () => ({
    example: {
      value: 0,
    },
    code: [
      `
      <minus-plus-number-input :isActive="false" />
      `,
      `
      <minus-plus-number-input :isActive="true" :min="10" :max="100" v-model="value" />
      `,
      `
      <button class="gt-button">버튼</button>
      `,
      `
      <button class="gt-button gt-button-small">버튼</button>
      `,
      `
      <button class="gt-button gt-button-xsmall">버튼</button>
      `,
      `
      <button class="gt-button gt-button-primary">버튼</button>
      <button class="gt-button gt-button-secondary">버튼</button>
      <button class="gt-button gt-button-info">버튼</button>
      <button class="gt-button gt-button-ghost">버튼</button>
      <button class="gt-button gt-button-text">버튼</button>
      <button class="gt-button gt-button-disabled">버튼</button>
      `,
      `
      <bill-layout>
            <div style="background-color: yellowgreen; width: 100%; height: 100px">첫번째</div>
            <span style="background-color: peru; width: 100%; height: 50px">두번째</span>
            <p style="background-color: darkgreen; color: white; width: 100%">세번째</p>
            <p>네번째</p>
      </bill-layout>
      `,
      `
      <form-layout>
        <template #title> <i class="fal fa-receipt"></i> 상품 상세 정보</template>
        <template #content>
          <div style="height: 150px; background-color: black; color: white">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus placeat fugit quasi perferendis iste
            suscipit, nemo veritatis sit aut maiores dolores, numquam cum asperiores est non ad vel laboriosam libero quam
            quidem quisquam! Tenetur, nam dolore aspernatur qui iste deleniti tempore magni itaque vero quos minus aperiam
            distinctio placeat nihil nobis voluptas ex neque repellendus dolores? Quis fugiat velit odio est officia odit
            voluptas quas reprehenderit expedita! Sapiente voluptates deserunt aperiam distinctio voluptatem minus est,
            accusamus nulla commodi dolorum illo consequuntur numquam qui fuga voluptatibus pariatur sunt quod natus quis
            laboriosam similique consequatur animi suscipit! In quaerat iusto natus beatae.
          </div>
        </template>
      </form-layout>
      `,
      `
      <column-layout>
        <template #title>
          <div style="background-color: black; color: white">제목</div>
        </template>
        <template #content>
          <div style="background-color: peru">내용</div>
        </template>
      </column-layout>

      <column-layout>
        <template #full-content>
          <div>제목을 사용하지 않고 한줄을 모두 사용하는 경우</div>
        </template>
      </column-layout>
      `,
      `
        <div>
          <button class="gt-button gt-button-primary" @click="$GabiaModal.show('guide-temp-modal')">누르세요</button>
        </div>
        <info-modal name="guide-temp-modal">
          <template #title> 제목 </template>
          <template #contet> 내용 </template>
        </info-modal>
      `,
    ],
  }),
  created() {
    this.$GlobalLoading.hide();
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  text-align: start;
}
h1 {
  font-size: 32px;
}
// required class
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

// optional
.prism-editor__textarea:focus {
  outline: none;
}
</style>
